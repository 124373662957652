/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.usability.gov/"
  }, "Usability.gov")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pttrns.com/"
  }, "ux patterns - pttrns")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.uisources.com/"
  }, "ux - ui sources")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lawsofux.com/"
  }, "laws of ux")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://uxtools.co/challenges/"
  }, "ux tools ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.uxchallenge.co/"
  }, "ux challenge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.uxwbchallenge.com/challenges"
  }, "ux bc challenge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sharpen.design/"
  }, "sharpe design")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cantunsee.space/"
  }, "cantusee")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
